import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import {Button, Message, Input, MessageBox, Loading, Alert} from 'element-ui'
import httpRequest from '@/utils/httpRequest'

Vue.component(Button.name, Button)
Vue.component(Input.name, Input)
Vue.component(Alert.name, Alert)
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$http = httpRequest
Vue.prototype.$loading = Loading.service

Vue.config.productionTip = false

new Vue({
    router,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
