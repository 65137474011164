import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/sms/index.vue')
    },
    {
        path: '/sms/oauth',
        name: 'oauth',
        component: () => import('@/views/sms/oauth.vue')
    },
    {
        path: '/sms/app',
        name: 'app',
        component: () => import('@/views/sms/app.vue')
    },
    {
        path: '/sms/order',
        name: 'order',
        component: () => import('@/views/sms/order.vue')
    },
    {
        path: '/sms/pay-result',
        name: 'pay-result',
        component: () => import('@/views/sms/pay-result.vue')
    },
    {
        path: '/sms/activation',
        name: 'activation',
        component: () => import('@/views/sms/activation.vue')
    },
    {
        path: '/sms/qrcode',
        name: 'qrcode',
        component: () => import('@/views/sms/qrcode.vue')
    },
]

const router = new VueRouter({
    routes
})

export default router
